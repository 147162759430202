import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ConnectionDataDto } from '@/generated/SyncMyOrdersApi';
import { Button } from './ui/button';
import { Icons } from './icons';
import { availableOperators } from '@/config/connections';

interface ConnectionCardProps {
  connection: ConnectionDataDto;
}

export function ConnectionCard(props: ConnectionCardProps) {
  const { id, title, operatorName } = props.connection;

  const operator = availableOperators.find(
    (operator) => operator.operatorName === operatorName
  );

  const OperatorIcon = operator?.name ? Icons[operator.name] : undefined;

  return (
    <Link className="flex" to={`connections/${operatorName}/${id}`}>
      <Card className="flex flex-col break-all hover:bg-blue-200 [&>div>button]:hover:bg-primary/90">
        <div className="space-y-1.5 px-6 pt-6">
          <Avatar>
            <AvatarImage src={OperatorIcon} alt="" />
            <AvatarFallback>SL</AvatarFallback>
          </Avatar>
        </div>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardContent className="flex-1">
          <p>
            Slack is a messaging app for business that connects people to the
            information they need.
          </p>
        </CardContent>
        <CardFooter>
          <Button className="text-lg bg-blue-400">View connection</Button>
        </CardFooter>
      </Card>
    </Link>
  );
}
