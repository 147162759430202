import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { z, ZodTypeAny } from 'zod';

import { ConnectionSchema } from '@/lib/types';
import { inputTypes } from '@/config';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function camelToHuman(str: string) {
  const humanString = str.replace(/([A-Z])/g, ' $1').toLowerCase();
  return humanString.charAt(0).toUpperCase() + humanString.slice(1);
}

export function mapConnectionDefaultValues(fields: ConnectionSchema[]) {
  return fields.reduce(
    (acc: Record<string, any>, field: ConnectionSchema) => ({
      ...acc,
      [field.name]: field.value,
    }),
    {}
  );
}

export function createZodSchema(fields: ConnectionSchema[]) {
  const schema: Record<string, ZodTypeAny> = {};

  fields.forEach((field) => {
    let fieldSchema!: ZodTypeAny;

    if (field.type === inputTypes.TypeString) {
      fieldSchema = z
        .string()
        .min(5, { message: 'Must be 5 or more characters long.' });
    } else if (field.type === inputTypes.TypeBoolean) {
      fieldSchema = z.boolean();
    }

    schema[field.name] = fieldSchema;
  });

  return z.object(schema);
}
