import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';

export function SkeletonConnectionList() {
  return (
    <>
      <Skeleton className="w-[175px] h-8 rounded-sm" />
      <h1 className="my-6">Connections</h1>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {[1, 2, 3].map((item) => (
          <Card key={item}>
            <div className="px-6 pt-6">
              <Skeleton className="w-14 h-14 rounded-full" />
            </div>
            <CardHeader>
              <CardTitle>
                <Skeleton className="w-[50%] h-[30px] rounded-sm" />
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Skeleton className="w-full h-[100px] rounded-sm" />
            </CardContent>
            <CardFooter>
              <Skeleton className="w-[50%] h-[30px] rounded-sm" />
            </CardFooter>
          </Card>
        ))}
      </div>
    </>
  );
}
