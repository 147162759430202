const operator = {
  slack: 'SlackBotMessageOperator',
};

export const availableOperators = [
  {
    title: 'Slack',
    name: 'slack',
    description:
      'Slack is a messaging app for business that connects people to the information they need.',
    operatorName: operator.slack,
  },
];
