import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Switch } from '@/components/ui/switch';
import { ConnectionSchema } from '@/lib/types';

interface CheckboxInputProps extends ConnectionSchema {}

export function CheckboxInput(props: CheckboxInputProps) {
  const { name } = props;

  return (
    <FormField
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-row items-center space-x-3 space-y-0">
          <FormControl>
            <Switch checked={field.value} onCheckedChange={field.onChange} />
          </FormControl>
          <FormLabel>{field.name}</FormLabel>
        </FormItem>
      )}
    />
  );
}
