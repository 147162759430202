import { createBrowserRouter } from 'react-router-dom';
import { PrivateRoute } from '@/router/PrivateRoute';
import { Layout } from '@/components/layouts/layout';
import { Connections } from '@/pages/connections';
import { Connection } from '@/pages/connection';
import { Scenarios } from '@/pages/scenarios';
import { Analytics } from '@/pages/analytics';
import { CreateConnection } from '@/pages/create-connection';
import { Login } from '@/pages/ login';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Connections />
          </PrivateRoute>
        ),
      },
      {
        path: '/connections/:operatorId/:connectionId',
        element: (
          <PrivateRoute>
            <Connection />
          </PrivateRoute>
        ),
      },
      {
        path: '/connection/:operatorId/create',
        element: (
          <PrivateRoute>
            <CreateConnection />
          </PrivateRoute>
        ),
      },
      {
        path: '/scenarios',
        element: (
          <PrivateRoute>
            <Scenarios />
          </PrivateRoute>
        ),
      },
      {
        path: '/analytics',
        element: (
          <PrivateRoute>
            <Analytics />
          </PrivateRoute>
        ),
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '*',
        element: <>404</>,
      },
    ],
  },
]);
