/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ExecutionGraphDto {
  steps?: Record<string, ExecutionGraphStepDto>;
  executionPlan?: Record<string, string[]>;
  entryPoint?: string;
}

export interface ExecutionGraphStepDto {
  id?: string;
  operatorId?: string;
  connectionId?: string;
  inputMapping?: Record<string, OperandData>;
}

export interface OperandData {
  type?: 'Immediate' | 'Reference';
  value?: string;
}

export interface OperandMetadata {
  type?:
    | 'TypeInteger'
    | 'TypeString'
    | 'TypeBoolean'
    | 'TypeDouble'
    | 'TypeObject'
    | 'TypeArray';
  metadata?: string;
}

export interface ScenarioDto {
  id?: string;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  updated?: string;
  name?: string;
  description?: string;
  executionGraph?: ExecutionGraphDto;
  inputSchema?: Record<string, OperandMetadata>;
  outputSchema?: Record<string, OperandMetadata>;
}

export interface ActualValue {
  metadata?:
    | 'TypeInteger'
    | 'TypeString'
    | 'TypeBoolean'
    | 'TypeDouble'
    | 'TypeObject'
    | 'TypeArray';
  value?: object;
}

export interface ScenarioInstanceDto {
  id?: string;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  updated?: string;
  invocationState?: 'NotStarted' | 'Running' | 'Finished';
  invocationStatus?: 'Unknown' | 'Success' | 'Error';
  scenarioId?: string;
  inputs?: Record<string, ActualValue>;
  outputs?: Record<string, ActualValue>;
}

export interface ConnectionData {
  parameters?: Record<string, string>;
}

export interface ConnectionDataDto {
  id?: string;
  title?: string;
  connectionData?: ConnectionData;
  operatorName?: string;
}

export interface OperationDto {
  name?: string;
  inputSchema?: Record<string, OperandMetadata>;
  outputSchema?: Record<string, OperandMetadata>;
}

export interface OperatorDto {
  name?: string;
  supportedOperations?: OperationDto[];
  connectionSchema?: SchemaData;
  external?: boolean;
}

export interface SchemaData {
  definition?: Record<string, OperandMetadata>;
}

export interface SchemaDataElementModel {
  name?: string;
  metadata?: string;
  type?:
    | 'TypeInteger'
    | 'TypeString'
    | 'TypeBoolean'
    | 'TypeDouble'
    | 'TypeObject'
    | 'TypeArray';
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.syncmyorders.com',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title SMO API
 * @version 1
 * @baseUrl https://api.syncmyorders.com
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags scenario-controller
     * @name UpdateScenario
     * @request POST:/api/v1/scenarios/{id}/update
     * @secure
     */
    updateScenario: (
      id: string,
      query: {
        id: string;
        scenarioDto: ScenarioDto;
      },
      params: RequestParams = {}
    ) =>
      this.request<ScenarioDto, any>({
        path: `/api/v1/scenarios/${id}/update`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags scenario-controller
     * @name ScheduleScenario
     * @request POST:/api/v1/scenarios/{id}/schedule
     * @secure
     */
    scheduleScenario: (
      id: string,
      query: {
        id: string;
        scenarioDto: ScenarioDto;
      },
      params: RequestParams = {}
    ) =>
      this.request<ScenarioInstanceDto, any>({
        path: `/api/v1/scenarios/${id}/schedule`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags scenario-controller
     * @name UpdateScenario1
     * @request POST:/api/v1/scenarios/{id}/delete
     * @secure
     */
    updateScenario1: (
      id: string,
      query: {
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ScenarioDto, any>({
        path: `/api/v1/scenarios/${id}/delete`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags scenario-controller
     * @name ResumeScenarioInstance
     * @request POST:/api/v1/scenario-instance/{id}/resume
     * @secure
     */
    resumeScenarioInstance: (
      id: string,
      query: {
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ScenarioInstanceDto, any>({
        path: `/api/v1/scenario-instance/${id}/resume`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags scenario-controller
     * @name PauseScenarioInstance
     * @request POST:/api/v1/scenario-instance/{id}/pause
     * @secure
     */
    pauseScenarioInstance: (
      id: string,
      query: {
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ScenarioInstanceDto, any>({
        path: `/api/v1/scenario-instance/${id}/pause`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags scenario-controller
     * @name CancelScenarioInstance
     * @request POST:/api/v1/scenario-instance/{id}/cancel
     * @secure
     */
    cancelScenarioInstance: (
      id: string,
      query: {
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ScenarioInstanceDto, any>({
        path: `/api/v1/scenario-instance/${id}/cancel`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags connections-controller
     * @name UpdateConnection
     * @request POST:/api/v1/connections/{id}/update
     * @secure
     */
    updateConnection: (
      id: string,
      data: ConnectionDataDto,
      params: RequestParams = {}
    ) =>
      this.request<ConnectionDataDto, any>({
        path: `/api/v1/connections/${id}/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags connections-controller
     * @name DeleteConnection
     * @request POST:/api/v1/connections/{id}/delete
     * @secure
     */
    deleteConnection: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/connections/${id}/delete`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags connections-controller
     * @name CreateConnection
     * @request POST:/api/v1/connections/create
     * @secure
     */
    createConnection: (data: ConnectionDataDto, params: RequestParams = {}) =>
      this.request<ConnectionDataDto, any>({
        path: `/api/v1/connections/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags scenario-controller
     * @name GetAllScenarios
     * @request GET:/api/v1/scenarios
     * @secure
     */
    getAllScenarios: (params: RequestParams = {}) =>
      this.request<ScenarioDto[], any>({
        path: `/api/v1/scenarios`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags scenario-controller
     * @name GetScenarioById
     * @request GET:/api/v1/scenarios/{id}
     * @secure
     */
    getScenarioById: (
      id: string,
      query: {
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ScenarioDto, any>({
        path: `/api/v1/scenarios/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags scenario-controller
     * @name GetAllScenarioInstances
     * @request GET:/api/v1/scenario-instances
     * @secure
     */
    getAllScenarioInstances: (params: RequestParams = {}) =>
      this.request<ScenarioInstanceDto[], any>({
        path: `/api/v1/scenario-instances`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags scenario-controller
     * @name GetScenarioInstanceById
     * @request GET:/api/v1/scenario-instances/{id}
     * @secure
     */
    getScenarioInstanceById: (
      id: string,
      query: {
        id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ScenarioInstanceDto, any>({
        path: `/api/v1/scenario-instances/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags operators-controller
     * @name GetAllOperators
     * @request GET:/api/v1/operators
     * @secure
     */
    getAllOperators: (params: RequestParams = {}) =>
      this.request<OperatorDto[], any>({
        path: `/api/v1/operators`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags operators-controller
     * @name GetOperatorByName
     * @request GET:/api/v1/operators/{name}
     * @secure
     */
    getOperatorByName: (name: string, params: RequestParams = {}) =>
      this.request<OperatorDto, any>({
        path: `/api/v1/operators/${name}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags operators-controller
     * @name GetConnectionSchemaForOperatorByName
     * @request GET:/api/v1/operators/{name}/connection-schema
     * @secure
     */
    getConnectionSchemaForOperatorByName: (
      name: string,
      params: RequestParams = {}
    ) =>
      this.request<SchemaDataElementModel[], any>({
        path: `/api/v1/operators/${name}/connection-schema`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags connections-controller
     * @name GetConnections
     * @request GET:/api/v1/connections
     * @secure
     */
    getConnections: (params: RequestParams = {}) =>
      this.request<ConnectionDataDto[], any>({
        path: `/api/v1/connections`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags connections-controller
     * @name GetConnection
     * @request GET:/api/v1/connections/{id}
     * @secure
     */
    getConnection: (id: string, params: RequestParams = {}) =>
      this.request<ConnectionDataDto, any>({
        path: `/api/v1/connections/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  external = {
    /**
     * No description
     *
     * @tags external-systems-controller
     * @name Test
     * @request GET:/external/microsoft
     * @secure
     */
    test: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/external/microsoft`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
}
