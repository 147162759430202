import { Type } from '@/lib/types';

export const menu = [
  {
    title: 'connections',
    to: '/',
  },
  {
    title: 'scenarios',
    to: '/scenarios',
  },
  {
    title: 'analytics',
    to: '/analytics',
  },
];

export const inputTypes: Record<string, Type> = {
  TypeInteger: 'TypeInteger',
  TypeString: 'TypeString',
  TypeBoolean: 'TypeBoolean',
  TypeDouble: 'TypeDouble',
  TypeObject: 'TypeObject',
  TypeArray: 'TypeArray',
};
