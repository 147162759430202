import * as Sentry from '@sentry/react';

const STAGE = process.env.NODE_ENV || 'development';

export function init() {
  if (STAGE === 'development') return;
  Sentry.init({
    dsn: 'https://0a1a278b9ed555d05505c32e2785b3d9@o4507932747890688.ingest.us.sentry.io/4507944424964096',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/api\.syncmyorders\.com/],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
