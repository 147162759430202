import { ConnectionCard } from '@/components/connection-card';
import { ConnectionDataDto } from '@/generated/SyncMyOrdersApi';

interface ConnectionListProps {
  connections: ConnectionDataDto[];
}

export function ConnectionList(props: ConnectionListProps) {
  const { connections } = props;

  return (
    <>
      {connections?.map((connection: ConnectionDataDto) => (
        <ConnectionCard key={connection.id} connection={connection} />
      ))}
    </>
  );
}
