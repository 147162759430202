import AuthButton from '@/components/auth-button';
import { useAuth0 } from '@auth0/auth0-react';

export function Login() {
  const { isAuthenticated } = useAuth0();

  const message = isAuthenticated ? (
    'You are already signed in.'
  ) : (
    <>
      <b>Sign in now</b> to explore all our integration options and get started.
    </>
  );

  return (
    <section className="flex flex-col justify-center items-center h-[calc(100vh-300px)]">
      <div className="flex flex-col items-start">
        <h2 className="max-w-96 font-light mb-6">{message}</h2>
        <AuthButton />
      </div>
    </section>
  );
}
