import { CreateConnectionButton } from '@/components/create-connection-button';
import { useCustomQuery } from '@/hooks/api';
import { ConnectionList } from '@/components/connection-list';
import { getConnections } from '@/queries';
import { QueryKeys } from '@/queries/query-keys';
import { SkeletonConnectionList } from '@/components/skeleton-connection-list';

export function Connections() {
  const { data: connections, isFetching } = useCustomQuery({
    queryKey: [QueryKeys.Connections],
    queryFn: getConnections,
  });

  if (isFetching) {
    return <SkeletonConnectionList />;
  }

  return (
    <section>
      <CreateConnectionButton />
      <h1 className="my-6">Connections</h1>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        <ConnectionList connections={connections} />
      </div>
    </section>
  );
}
