import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';

export function SkeletonForm() {
  return (
    <Card className="w-full lg:max-w-[50%]">
      <CardHeader>
        <CardTitle>
          <Skeleton className="h-[30px] w-[150px] rounded-sm" />
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <Skeleton className="h-[20px] w-[20%] rounded-sm" />
        <Skeleton className="h-[30px] w-full rounded-sm" />
        <Skeleton className="h-[20px] w-[20%] rounded-sm" />
        <Skeleton className="h-[30px] w-full rounded-sm" />
      </CardContent>
      <CardFooter className="flex justify-between">
        <Skeleton className="h-[30px] w-[60px] rounded-sm" />
        <Skeleton className="h-[30px] w-[60px] rounded-sm" />
      </CardFooter>
    </Card>
  );
}
