import { Outlet } from 'react-router-dom';
import MainNav from './main-nav';
import MobileNav from './mobile-nav';

export function Layout() {
  return (
    <>
      <header className="container mx-auto px-4 lg:px-8">
        <MainNav />
        <MobileNav />
      </header>
      <main className="container mx-auto px-4 py-6 lg:px-8">
        <Outlet />
      </main>
    </>
  );
}
